import { FC } from 'react';

import CheckboxChip from 'bloko/blocks/chips/CheckboxChip';
import MultiLineContainer from 'bloko/blocks/chips/MultiLineContainer';
import { CrossScaleSmallEnclosedFalse, IconColor } from 'bloko/blocks/icon';
import IconDynamic from 'bloko/blocks/icon/IconDynamic';

import { NovaChipsOption } from 'src/components/NovaFilters/components/NovaChipsSelect';

import styles from './chips-list.less';

export interface NovaChipsSelectProps {
    name: string;
    options: NovaChipsOption<string>[];
    onClear: (id: string) => void;
}

const NovaChipsList: FC<NovaChipsSelectProps> = ({ name, options, onClear }) => {
    return (
        <MultiLineContainer>
            {options.map(({ id, title }) => (
                <CheckboxChip
                    key={`${name}_${id}`}
                    id={id}
                    value={id}
                    data-qa={`serp__novafilter-xs-${name}-${id}`}
                    name={name}
                    checked
                    readOnly
                >
                    {title}
                    <span className={styles.chipsListIcon}>
                        <IconDynamic
                            onClick={() => {
                                onClear(id);
                            }}
                        >
                            <CrossScaleSmallEnclosedFalse highlighted={IconColor.Blue60} initial={IconColor.Gray50} />
                        </IconDynamic>
                    </span>
                </CheckboxChip>
            ))}
        </MultiLineContainer>
    );
};

export default NovaChipsList;
