import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaFilter from 'src/components/NovaFilters/components/Magritte/NovaFilter';
import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import { NovaFilterProps } from 'src/components/NovaFilters/components/NovaFilter';
import NovaFilterContent from 'src/components/NovaFilters/components/NovaFilterContent';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import translation from 'src/components/translation';

import LabelMobile from 'src/components/NovaFilters/vacancies/LabelMobile';

const TrlKeys = {
    title: 'novafilters.exclusion',
};

const Labels: TranslatedComponent<NovaFilterProps & { isMagritte?: boolean }> = ({ trls, isMagritte, name }) => {
    if (isMagritte) {
        return <MagritteNovaFilter title={trls[TrlKeys.title]} name={name} shouldSendCountsRequest />;
    }

    return (
        <NovaControl mobileView={<LabelMobile title={trls[TrlKeys.title]} />}>
            <NovaFilterWrapper title={trls[TrlKeys.title]}>
                <NovaFilterContent name={name} shouldSendCountsRequest />
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(Labels);
