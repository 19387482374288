import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { push } from 'connected-react-router';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import Conversion from 'bloko/blocks/conversion';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import useResetLink from 'src/components/NovaFilters/hooks/useResetLink';
import { SendFilterFormWrapped } from 'src/components/NovaFilters/hooks/useSendFilterForm';

const TrlKeys = {
    similarFilterSave: 'novafilters.similarSavedSearch.filterSave',
    reset: 'novafilters.reset',
    filterSave: 'novafilters.save',
    foundOne: 'novaFilters.vacancies.found.one',
    foundSome: 'novaFilters.vacancies.found.some',
    foundMany: 'novaFilters.vacancies.found.many',
    foundZero: 'vacancySearch.title.found.zero',
};

interface Props {
    submitFilters: SendFilterFormWrapped;
    isIndexAnonSearchRedirectDisabled?: boolean;
}

const CounterButton: TranslatedComponent<Props> = ({ trls, submitFilters, isIndexAnonSearchRedirectDisabled }) => {
    const searchMobileControls = useSelector((state) => state.searchMobileControls);
    const counts = useSelector((state) => state.searchCounts);
    const [isLoadReset, setIsLoadReset] = useState(false);
    const [isLoadFilters, setIsLoadFilters] = useState(false);
    const searchType = useSelector((state) => state.searchClustersSettings.type);
    // tempexp_PORTFOLIO-30605_start
    const dispatch = useDispatch();
    const vacancySearchResult = useSelector((state) => state.vacancySearchResult);
    const fullSearchLink = `${paths.vacancySearch}?${vacancySearchResult?.queries.itemsOnPageBase ?? ''}`;
    // tempexp_PORTFOLIO-30605_end

    useEffect(() => {
        setIsLoadFilters(counts.isLoad);
    }, [counts]);

    const enableSimilarSavedSearch = useSelector((state) => state.vacancySearchResult.enableSimilarSavedSearch);
    const haveAnyChanges = useSelector((state) => state.searchClustersChanged);

    const resetLink = useResetLink();
    const label = enableSimilarSavedSearch ? trls[TrlKeys.similarFilterSave] : trls[TrlKeys.filterSave];
    return (
        <div
            data-qa="novafilters-apply-filter-buttons"
            className={classnames('novafilters__close', {
                // tempexp_PORTFOLIO-30605_start
                'novafilters__close_mobile-only': !isIndexAnonSearchRedirectDisabled,
                'novafilters__close-sticky': isIndexAnonSearchRedirectDisabled,
                // tempexp_PORTFOLIO-30605_end
            })}
        >
            <div
                className={classnames('novafilters-reset-link', {
                    'novafilters-reset-link_no-mobile': searchMobileControls,
                })}
            >
                <Button
                    Element={Link}
                    to={resetLink}
                    kind={ButtonKind.Primary}
                    appearance={ButtonAppearance.Outlined}
                    onClick={() => {
                        setIsLoadReset(true);
                    }}
                    loading={isLoadReset && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />}
                    stretched
                    // tempexp_PORTFOLIO-30605_start
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    style={{ position: isIndexAnonSearchRedirectDisabled ? 'sticky' : 'relative' }}
                    // tempexp_PORTFOLIO-30605_end
                >
                    {trls[TrlKeys.reset]}
                </Button>
            </div>
            <Button
                kind={haveAnyChanges ? ButtonKind.Success : ButtonKind.Primary}
                onClick={() => {
                    setIsLoadFilters(true);
                    submitFilters();
                    Analytics.sendHHEventButtonClick(`${searchType}_search_filter_apply_all`, {
                        hhtmSource: `${searchType}_search_filter`,
                    });
                    if (isIndexAnonSearchRedirectDisabled) {
                        dispatch(push(fullSearchLink));
                    }
                }}
                loading={isLoadFilters && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />}
                stretched
            >
                {(!searchMobileControls || isIndexAnonSearchRedirectDisabled) && label}
                {searchMobileControls && !isIndexAnonSearchRedirectDisabled && !isLoadFilters && (
                    <Conversion
                        value={counts.value}
                        zero={trls[TrlKeys.foundZero]}
                        one={trls[TrlKeys.foundOne]}
                        some={trls[TrlKeys.foundSome]}
                        many={trls[TrlKeys.foundMany]}
                        hasValue={false}
                        format={(trl) =>
                            format(trl, {
                                '{0}': numberFormatter.format(String(counts.value), {
                                    groupSeparator: NON_BREAKING_SPACE,
                                }),
                            })
                        }
                    />
                )}
            </Button>
        </div>
    );
};

export default translation(CounterButton);
