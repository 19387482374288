import { useMemo, Fragment } from 'react';

import { Cell, CellText, Action, Divider } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { CoreField } from 'bloko/common/tree/types';
import { formatToReactComponent, format } from 'bloko/common/trl';

import SelectedItem from 'src/components/NovaFilters/components/MobileSelectedItem';
import translation from 'src/components/translation';

interface SelectedIndustryProps {
    collection: TreeCollection;
    selectedValues: string[];
    onClear: (id: string) => void;
    onClick: (parentId: string) => void;
    isMagritte?: boolean;
}

interface ParentsWithChildren {
    id: string;
    name: string;
    children: string[];
}

const TrlKeys = {
    more: 'novaFilters.industryAndSubIndustry.more',
};

const getParentsWithChildren = (collection: TreeCollection, selectedValues: string[]): ParentsWithChildren[] => {
    if (!collection) {
        return [];
    }
    const dict: Record<string, ParentsWithChildren> = {};
    selectedValues.forEach((value) => {
        const [parentId, childId] = value.split('.');
        const parent = collection.getModel(parentId);
        if (!parent) {
            return;
        }

        // user have parent category
        // we need add industry and all subs
        if (!childId) {
            const children = collection.getChildren(parentId);
            if (!children) {
                return;
            }
            dict[parentId] = {
                id: parent[CoreField.Id],
                name: parent[CoreField.Text],
                children: children.map((child) => child[CoreField.Text]),
            };
            return;
        }

        // or save sub industry into parent
        if (childId) {
            const child = collection.getModel(value);
            if (!child) {
                return;
            }
            if (!dict[parentId]) {
                dict[parentId] = {
                    id: parent[CoreField.Id],
                    name: parent[CoreField.Text],
                    children: [child[CoreField.Text]],
                };
                return;
            }
            dict[parentId].children.push(child[CoreField.Text]);
        }
    });
    return Object.values(dict);
};

const SelectedIndustry: TranslatedComponent<SelectedIndustryProps> = ({
    collection,
    selectedValues,
    onClear,
    onClick,
    isMagritte,
    trls,
}) => {
    const industriesWithSubsIndustries: ParentsWithChildren[] = useMemo(
        () => getParentsWithChildren(collection, selectedValues),
        [collection, selectedValues]
    );
    if (selectedValues.length === 0 || !collection) {
        return null;
    }

    if (isMagritte) {
        return (
            <>
                {industriesWithSubsIndustries.map(({ id, name, children }, index) => (
                    <Fragment key={id}>
                        <Cell
                            align="top"
                            vertPadding
                            key={id}
                            right={
                                <Action
                                    icon={CrossOutlinedSize24}
                                    mode="secondary"
                                    onClick={() => {
                                        onClear(id);
                                    }}
                                />
                            }
                        >
                            <CellText>{name}</CellText>
                            {children.length > 0 && (
                                <CellText type="subtitle">{`${children[0]}${
                                    children.length > 1
                                        ? ` ${format(trls[TrlKeys.more], {
                                              '{0}': children.length - 1,
                                          })}`
                                        : ''
                                }`}</CellText>
                            )}
                        </Cell>
                        {index !== industriesWithSubsIndustries.length - 1 && <Divider />}
                    </Fragment>
                ))}
            </>
        );
    }

    return (
        <>
            {industriesWithSubsIndustries.map(({ id, name, children }) => (
                <Fragment key={id}>
                    <SelectedItem
                        onClick={() => {
                            onClick(id);
                        }}
                        onClear={() => {
                            onClear(id);
                        }}
                    >
                        <>
                            <Text strong>{name}</Text>
                            {children.length > 0 && (
                                <>
                                    <VSpacing base={1} />
                                    <Text Element="span">{children[0]}</Text>
                                </>
                            )}
                            {children.length > 1 && (
                                <Text Element="span">
                                    {' '}
                                    {formatToReactComponent(trls[TrlKeys.more], {
                                        '{0}': children.length - 1,
                                    })}
                                </Text>
                            )}
                        </>
                    </SelectedItem>
                    <VSpacing base={4} />
                </Fragment>
            ))}
        </>
    );
};

export default translation(SelectedIndustry);
