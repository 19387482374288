import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { push } from 'connected-react-router';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { CrossScaleMediumEnclosedFalse, IconColor } from 'bloko/blocks/icon';
import IconDynamic from 'bloko/blocks/icon/IconDynamic';
import IconLink from 'bloko/blocks/icon/IconLink';
import Link, { LinkKind, LinkAppearance } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import useToggleSearchPreference from 'src/components/SearchSorts/hooks/useToggleSearchPreference';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './form-header.less';

const TrlKeys = {
    filterTitle: 'vacancySearch.filterTitle',
    reset: 'vacancySearch.reset',
};

const searchCountsAction = makeSetStoreField('searchCounts');

const FormHeader: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    const searchCounts = useSelector((state) => state.searchCounts);
    const actionPath = useSelector((state) => state.searchClustersSettings.actionPath);
    const isShowForm = useSelector((state) => state.searchPreference.isShown);
    const toggleSearchPreference = useToggleSearchPreference();
    // tempexp_PORTFOLIO-30605_start
    const isMainPage = useSelector(({ router }) => router.location.pathname === paths.indexPage);
    // tempexp_PORTFOLIO-30605_end

    if (!isShowForm) {
        return null;
    }
    return (
        <div className={classnames(styles.formHeader, { [styles.formHeaderMain]: isMainPage })}>
            <VSpacing base={4} />
            <div className={styles.formHeaderContent}>
                <div className={styles.formHeaderClose}>
                    <IconDynamic>
                        <IconLink
                            onClick={() => {
                                toggleSearchPreference(!isShowForm);
                            }}
                        >
                            <CrossScaleMediumEnclosedFalse highlighted={IconColor.Blue60} initial={IconColor.Gray80} />
                        </IconLink>
                    </IconDynamic>
                </div>
                <div className={styles.formHeaderTitle}>{trls[TrlKeys.filterTitle]}</div>
                <div className={styles.formHeaderReset}>
                    {!isMainPage && (
                        <Link
                            appearance={LinkAppearance.Pseudo}
                            kind={LinkKind.Secondary}
                            onClick={() => {
                                dispatch(searchCountsAction({ isLoad: true, value: searchCounts.value }));
                                dispatch(push(actionPath));
                            }}
                        >
                            {trls[TrlKeys.reset]}
                        </Link>
                    )}
                </div>
            </div>
            <VSpacing base={3} />
        </div>
    );
};

export default translation(FormHeader);
