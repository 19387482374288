import { useMemo, FC } from 'react';

import { Cell, CellText, Action } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import VSpacing from 'bloko/blocks/vSpacing';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { AdditionalDefault, CoreField } from 'bloko/common/tree/types';

import SelectedItem from 'src/components/NovaFilters/components/MobileSelectedItem';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface SelectedRolesProps {
    collection: TreeCollection<AdditionalDefault>;
    selectedValues: string[];
    onClear: () => void;
    onClick?: () => void;
    isMagritte?: boolean;
}

const SelectedRoles: FC<SelectedRolesProps> = ({ collection, selectedValues, onClear, onClick, isMagritte }) => {
    const value = useMemo(() => {
        if (selectedValues.length === 0 || !collection) {
            return null;
        }
        return selectedValues.map((id, index) => {
            const role = collection.getModel(id);
            if (!role) {
                return '';
            }
            return `${index > 0 ? `${NON_BREAKING_SPACE}• ` : ''}${role[CoreField.Text]}`;
        });
    }, [collection, selectedValues]);
    if (!value) {
        return null;
    }

    if (isMagritte) {
        return (
            <Cell align="top" right={<Action icon={CrossOutlinedSize24} mode="secondary" onClick={onClear} />}>
                <CellText>
                    {selectedValues.map((id, index) => {
                        const role = collection.getModel(id);
                        if (!role) {
                            return '';
                        }
                        return `${index > 0 ? `${NON_BREAKING_SPACE}· ` : ''}${role[CoreField.Text]}`;
                    })}
                </CellText>
            </Cell>
        );
    }

    return (
        <>
            <SelectedItem onClick={onClick} onClear={onClear}>
                {selectedValues.map((id, index) => {
                    const role = collection.getModel(id);
                    if (!role) {
                        return null;
                    }
                    return (
                        <span key={role[CoreField.Id]}>
                            {index > 0 ? `${NON_BREAKING_SPACE}• ` : ''}
                            {role[CoreField.Text]}
                        </span>
                    );
                })}
            </SelectedItem>
            <VSpacing base={4} />
        </>
    );
};

export default SelectedRoles;
