import Button, { ButtonKind } from 'bloko/blocks/button';
import { H2Section } from 'bloko/blocks/header';
import { ChevronScaleMediumKindLeft } from 'bloko/blocks/icon';
import InputText, { InputChangeHandler } from 'bloko/blocks/inputText';
import Modal, { ModalFooter, ModalHeader, ModalContent } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './nova-modal.less';

const TrlKeys = {
    save: 'novaModal.save',
};

export interface NovaModalProps {
    title: string;
    searchQuery: string;
    searchPlaceholder?: string;
    showModal: boolean;
    onBack: () => void;
    onClose: () => void;
    onChangeSearchQuery: InputChangeHandler;
}

const NovaModal: TranslatedComponent<NovaModalProps> = ({
    trls,
    children,
    title,
    searchQuery,
    searchPlaceholder,
    showModal,
    onBack,
    onClose,
    onChangeSearchQuery,
}) => {
    return (
        <Modal visible={showModal} closeButtonShow={false} escapePress={false} onClose={onClose}>
            <ModalHeader>
                <div className={styles.novaModalHeader}>
                    <div className={styles.novaModalHeaderIcon} onClick={onBack}>
                        <ChevronScaleMediumKindLeft />
                    </div>
                    <H2Section>{title}</H2Section>
                </div>
                <VSpacing base={3} />
                <InputText
                    showClearButton
                    value={searchQuery}
                    placeholder={searchPlaceholder}
                    onChange={onChangeSearchQuery}
                />
            </ModalHeader>
            <ModalContent>{children}</ModalContent>
            <ModalFooter>
                <Button kind={ButtonKind.Primary} onClick={onClose} stretched>
                    {trls[TrlKeys.save]}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default translation(NovaModal);
